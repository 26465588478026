import React from 'react'
import { Container } from 'components/common'
import talk from 'assets/icons/talk.svg'
import GetForm from '../../landing/Contact/GetForm/index'
import { Wrapper, SkillsWrapper, Details, Thumbnail } from './styles'

export const Information = () => (
	<Wrapper>
		<SkillsWrapper as={Container}>
			<Thumbnail>
				<img src={talk} alt="Digital Marketing Agency Careers" />
			</Thumbnail>
			<Details>
				<h1>Get Connected</h1>
				<p>Send us an email below to get connected with a specialist.
				</p>
				<GetForm />
			</Details>
		</SkillsWrapper>
	</Wrapper>
)