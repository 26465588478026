import React from 'react'
import { Layout, SEO } from 'components/common'
import { Banner, Information } from 'components/contact'

export default () => (
	<Layout>
		<SEO />
		<Banner />
		<Information />
	</Layout>
)